<template>
    <div id="app">
        <!-- NavBar -->
        <nav id="main-nav" class="main-nav site-max-width">
            <div class="inner main-nav-container">
                <div class="main-nav-logo">
                    <router-link :to="{ name: 'HomePage-' + $route.meta.lang }" class="main-nav-logo-link">
                        <img
                            src="@/assets/imgs/logo-adlm.svg"
                            alt="Les amis de la montage : Épisodes"
                        />
                    </router-link>
                </div>
                <div class="main-nav-links">
                    <router-link
                        :to="{ name: 'Episodes-' + $route.meta.lang }"
                        class="main-nav-link"
                        title="Épisodes"
                        ><span>{{ $t('Épisodes') }}</span></router-link
                    >
                    <router-link :to="{ name: 'About-' + $route.meta.lang }" class="main-nav-link" title="À Propos"
                        ><span>{{ $t('À Propos') }}</span></router-link
                    >
                    <router-link :to="{ name: 'Contact-' + $route.meta.lang }" class="main-nav-link" title="Contact"
                        ><span>Contact</span></router-link
                    >

                    <a href="#" class="main-nav-link" title="Partager" @click.prevent="toggleShareDialog(true)"><span>{{ $t('Partager') }}</span></a>
                </div>
            </div>

            <router-link v-if="contentIsLoaded && $route.meta.lang === 'fr'" :to="content.urls['en']" class="lang" title="EN">EN</router-link>
            <router-link v-else-if="contentIsLoaded && $route.meta.lang === 'en'" :to="content.urls['fr']" class="lang" title="FR">FR</router-link>
        </nav>

        <div class="main-wrapper" :class="{ 'fluid': $route.name && $route.name.includes('Contact') }">
            <transition name="page" mode="out-in">
                <router-view />
            </transition>
        </div>

        <!-- Footer -->
        <footer id="main-footer" class="main-footer site-max-width" v-if="globalsIsLoaded">
            <div class="inner main-footer-container">
                <p class="copy"><span v-html="globals.footer.texteSimple"></span></p>
                <div class="reseaux">
                    <a :href="globals.footer.reseaux.instagram" target="_blank" rel="noopener noreferrer" class="reseaux-link" title="Instagram">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon icon-tabler icon-tabler-brand-instagram"
                            height="44"
                            viewBox="0 0 24 24"
                            stroke-width="1"
                            stroke="#000"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <rect x="4" y="4" width="16" height="16" rx="4" />
                            <circle cx="12" cy="12" r="3" />
                            <line x1="16.5" y1="7.5" x2="16.5" y2="7.501" />
                        </svg>
                    </a>
                    <!-- <a :href="globals.footer.reseaux.twitter" target="_blank" rel="noopener noreferrer" class="reseaux-link" title="Twitter">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="43.285"
                            height="35.626"
                            viewBox="55.626 0.001 43.285 35.626"
                        >
                            <path
                                d="M98.733 4.271c-1.374.622-2.895.999-4.378 1.248.704-.119 1.738-1.393 2.153-1.909.632-.786 1.157-1.681 1.452-2.649.039-.075.067-.17-.009-.227-.095-.038-.156-.019-.232.019a22.347 22.347 0 0 1-5.191 1.986.345.345 0 0 1-.357-.095c-.152-.17-.293-.331-.454-.464A9.154 9.154 0 0 0 89.203.64a8.752 8.752 0 0 0-3.856-.621 9.22 9.22 0 0 0-3.658 1.033 9.415 9.415 0 0 0-2.951 2.411 9.047 9.047 0 0 0-1.762 3.53c-.304 1.242-.279 2.471-.094 3.726.028.209.009.236-.179.209-7.128-1.061-13.037-3.612-17.834-9.078-.208-.237-.32-.237-.491.018-2.1 3.147-1.081 8.201 1.541 10.685.35.331.709.662 1.096.964-.141.029-1.886-.159-3.441-.964-.208-.132-.313-.056-.331.18-.02.34.01.653.056 1.021.403 3.19 2.611 6.139 5.637 7.29.359.152.756.284 1.143.35-.69.152-1.399.259-3.375.105-.246-.048-.34.076-.246.312 1.485 4.047 4.69 5.252 7.091 5.938.321.056.643.056.966.132-.02.029-.039.029-.058.057-.796 1.214-3.557 2.117-4.843 2.566-2.338.818-4.884 1.185-7.355.942-.396-.059-.478-.054-.586 0-.108.067-.014.162.114.264.501.331 1.012.624 1.532.908a24.28 24.28 0 0 0 4.916 1.967c8.836 2.44 18.789.647 25.423-5.945 5.21-5.176 7.037-12.315 7.037-19.463 0-.279.33-.434.526-.581 1.356-1.019 2.449-2.238 3.46-3.599.227-.305.23-.577.23-.689v-.037c0-.115-.003-.08-.178 0"
                                fill-rule="evenodd"
                                data-name="Tracé 347"
                            />
                        </svg>
                    </a> -->
                    <a :href="globals.footer.reseaux.youtube" target="_blank" rel="noopener noreferrer" class="reseaux-link" title="Youtube">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="45.165"
                            height="35.626"
                            viewBox="118.91 0 45.165 35.626"
                        >
                            <path
                                d="M164.075 11.168C164.075 5 159.638 0 154.164 0h-25.343c-5.474 0-9.911 5-9.911 11.168v13.29c0 6.168 4.437 11.168 9.911 11.168h25.343c5.474 0 9.911-5 9.911-11.168v-13.29Zm-14.904 7.641-11.364 6.336c-.446.271-1.96-.092-1.96-.663V11.477c0-.579 1.526-.942 1.972-.655l10.879 6.669c.456.293.935 1.036.473 1.318"
                                fill-rule="evenodd"
                                data-name="Tracé 348"
                            />
                        </svg>
                    </a>
                    <a :href="globals.footer.reseaux.facebook" target="_blank" rel="noopener noreferrer" class="reseaux-link" title="Facebook">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="19.081"
                            height="35.626"
                            viewBox="184.075 0 19.081 35.626"
                        >
                            <path
                                d="m201.906 20.04.989-6.448h-6.186V9.408c0-1.764.864-3.483 3.635-3.483h2.812V.436S200.604 0 198.163 0c-5.095 0-8.424 3.088-8.424 8.678v4.914h-5.664v6.448h5.664v15.586h6.97V20.04h5.197Z"
                                fill-rule="evenodd"
                                data-name="Tracé 346"
                            />
                        </svg>
                    </a>
                    <a v-if="globals.footer.reseaux.linkedin" :href="globals.footer.reseaux.linkedin" target="_blank" rel="noopener noreferrer" class="reseaux-link" title="linkedin">
                        <svg xmlns="http://www.w3.org/2000/svg" height="45" viewBox="0 0 24 24"><path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/></svg>
                    </a>
                </div>
            </div>
        </footer>

        <!-- Share Dialog Component -->
        <share-dialog :isSharing="isSharing" :toggleShareDialog="toggleShareDialog"></share-dialog>

        <!-- Popup content -->
        <div class="content-dialog" :class="{ 'is-open': activatePopup }">
            <button class="close-button" @click.prevent="closePopup()"><svg><use href="#close"></use></svg></button>

            <div class="wysiwyg">
                <span v-html="globalsIsLoaded ? globals.footer.contenu : '&nbsp;'"></span>
            </div>

            <svg>
                <defs>
                    <symbol id="close" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x-square"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="9" y1="9" x2="15" y2="15"></line><line x1="15" y1="9" x2="9" y2="15"></line></symbol>
                </defs>
            </svg>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { initInViewAnimations } from '@/plugins/AnimationsLayer'
import ShareDialog from '@/components/ShareDialog'

export default {
    data() {
        return {
            isSharing: false,
            activatePopup: false,
        }
    },

    components: {
        ShareDialog,
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    setTimeout(() => {
                        initInViewAnimations()

                        if (this.globals.footer.activerLePopupAnnonce) {
                            if (window.localStorage.getItem('adlmdb-popup') !== 'true') {
                                this.activatePopup = true
                            }
                        }
                    }, 1425)
                }
            },
            immediate: true,
        },
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    methods: {
        toggleShareDialog(value) {
            this.isSharing = value
        },
        closePopup() {
            this.activatePopup = false
            window.localStorage.setItem('adlmdb-popup', 'true')
        },
    },

    metaInfo() {
        return {
            ...(this.seo.titleRaw && this.seo.title && { title: this.seo.title }),
            ...(this.seo.titleRaw && { titleTemplate: '%s ' + this.seo.titleRaw[2] }),

            meta: [
                {
                    vmid: 'og:title',
                    property: 'og:title',
                    content: this.seo.title,
                },

                {
                    vmid: 'description',
                    name: 'description',
                    content: this.seo.description,
                },
                {
                    vmid: 'og:description',
                    property: 'og:description',
                    content: this.seo.description,
                },
            ],
            link: [
                {
                    vmid: 'canonical',
                    rel: 'canonical',
                    href: window.location.href,
                },
            ],
        }
    },
}
</script>

<style lang="scss">
@import '@/assets/styles/main.scss';

#app {
    min-height: 100vh;
}

.page-first-load-ph {
    height: 100vh;
}

.page-enter-active,
.page-leave-active {
    transition-duration: 1.471s;
    transition-property: opacity, transform;
    // transition-timing-function: cubic-bezier(0.35, 0, 0.22, 1);

    [hero-transition-group] {
        transition: opacity 1s cubic-bezier(0.35, 0, 0.22, 1) 0.45s,
            transform 2.1s cubic-bezier(0.35, 0, 0.22, 1) 0s;
    }
}

.page-enter,
.page-leave-active {
    [hero-transition-group] {
        transform: translate3d(0, 45px, 0);
        opacity: 0;
    }
}
</style>
