<template>
    <div class="share-dialog" :class="{ 'is-open': isSharing }">
        <h3 class="dialog-title">Partagez cette page</h3>

        <button class="close-button" @click.prevent="close()"><svg><use href="#close"></use></svg></button>

        <div class="targets">
            <a class="button" @click.prevent.stop="openShareWindow('facebook')">
                <svg>
                    <use href="#facebook"></use>
                </svg>
                <span>Facebook</span>
            </a>

            <a class="button" @click.prevent.stop="openShareWindow('twitter')">
                <svg>
                    <use href="#twitter"></use>
                </svg>
                <span>Twitter</span>
            </a>
        </div>

        <div class="link">
            <div class="pen-url">{{ currentUrl }}</div>
            <button class="copy-link" @click.prevent.stop="copyLink()">Copier</button>
        </div>

        <svg>
            <defs>
                <symbol id="share-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-share"><path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path><polyline points="16 6 12 2 8 6"></polyline><line x1="12" y1="2" x2="12" y2="15"></line></symbol>

                <symbol id="facebook" viewBox="0 0 24 24" fill="#3b5998" stroke="#3b5998" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-facebook"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></symbol>

                <symbol id="twitter" viewBox="0 0 24 24" fill="#1da1f2" stroke="#1da1f2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-twitter"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></symbol>

                <symbol id="email" viewBox="0 0 24 24" fill="#777" stroke="#fafafa" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></symbol>

                <symbol id="linkedin" viewBox="0 0 24 24" fill="#0077B5" stroke="#0077B5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-linkedin"><path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path><rect x="2" y="9" width="4" height="12"></rect><circle cx="4" cy="4" r="2"></circle></symbol>

                <symbol id="close" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x-square"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="9" y1="9" x2="15" y2="15"></line><line x1="15" y1="9" x2="9" y2="15"></line></symbol>
            </defs>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'ShareDialog',

    props: {
        isSharing: {
            type: Boolean,
            default: false,
        },
        toggleShareDialog: {
            type: Function,
            required: true,
        },
        shareTitle: {
            type: String,
            default: 'ADLM - BD',
        },
    },

    computed: {
        currentUrl() {
            return encodeURI(window.location.href);
        },
        shareLinkUrls() {
            return {
                facebook:
                    'https://www.facebook.com/sharer/sharer.php?u=' +
                    encodeURI(this.currentUrl) +
                    '&title=' +
                    encodeURI(this.shareTitle) +
                    '',
                twitter:
                    'https://twitter.com/intent/tweet?url=' +
                    encodeURI(this.currentUrl) +
                    '&text=' +
                    encodeURI(this.shareTitle) +
                    '&via=',
                linkedin:
                    'https://www.linkedin.com/shareArticle?url=' +
                    encodeURI(this.currentUrl) +
                    '&title=' +
                    encodeURI(this.shareTitle) +
                    '',
            }
        },
    },

    methods: {
        close() {
            this.toggleShareDialog(false)
        },
        async copyLink() {
            /*global ClipboardItem*/
            const type = 'text/plain'
            const blob = new Blob([this.currentUrl], { type })
            const data = [new ClipboardItem({ [type]: blob })]

            await navigator.clipboard.write(data)

            this.toggleShareDialog(false)
        },
        openShareWindow(platform) {
            const dualScreenLeft = window.screenLeft
            const dualScreenTop = window.screenTop

            const width = window.innerWidth
            const height = window.innerHeight

            const left = width / 2 - 600 / 2 + dualScreenLeft
            const top = height / 2 - 600 / 2 + dualScreenTop

            const newWindow = window.open(
                this.shareLinkUrls[platform],
                this.shareTitle,
                'scrollbars=yes, width=' +
                    600 +
                    ', height=' +
                    600 +
                    ', top=' +
                    top +
                    ', left=' +
                    left
            )

            if (window.focus) {
                newWindow.focus()
            }
        },
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '@/assets/styles/02-Tools/_variables.scss';

svg {
  width: 20px;
  height: 20px;
  margin-right: 7px;
}

.dialog-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

button, .button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: auto;
  padding-top: 8px;
  padding-bottom: 8px;
  color: #777;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.1;
  letter-spacing: 1px;
  text-transform: capitalize;
  text-decoration: none;
  white-space: nowrap;
  border-radius: 4px;
  border: 1px solid #ddd;
  cursor: pointer;
}

button:hover, .button:hover {
  border-color: #cdd;
}

.share-button, .copy-link {
  padding-left: 30px;
  padding-right: 30px;
}

.share-button, .share-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.share-dialog {
  width: 95%;
  max-width: 500px;
  box-shadow: 0 8px 16px rgba(0,0,0,.15);
  z-index: -1;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 4px;
  background-color: #fff;

  transition: all $t1 $smooth;

  transform:  translate(-50%, -46%) scale(0.9);
  opacity: 0;
}

.share-dialog.is-open {
  z-index: 2;
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
}

header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.targets {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  margin-bottom: 20px;
}

.close-button {
    position: absolute;
    top: 20px;
    right: 20px;
  background-color: transparent;
  border: none;
  padding: 0;
}

.close-button svg {
  margin-right: 0;
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  background-color: #eee;
}

.pen-url {
  margin-right: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
